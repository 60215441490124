import React, {useCallback, useEffect, useRef, useState} from "react";

import ErrorMessage from "./components/ErrorMessage";
import Label from "./components/Label";
import {inputStyles} from "./utils/commonStyles";
import {randomId} from "./utils/randomId";

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> & {
  onChange: (input: string) => void;
  // onEnter gets called when the user hits the "enter" key.
  // The current text is passed as an argument.
  onEnter?: (input?: string) => unknown;
  onBlur?: () => void;
  icon?: string;
  label?: string;
  ariaLabel?: string;
  autoFocus?: boolean;
  errorMsg?: string;
  setValidation?: (validate: boolean) => string | void;
  autoComplete?: "on" | "off";
  inputValue?: string;
};

const TextInput: React.FC<Props> = ({
  className,
  placeholder,
  onChange,
  onEnter,
  onBlur,
  icon,
  label,
  setValidation,
  errorMsg,
  ariaLabel,
  defaultValue,
  autoFocus = false,
  autoComplete = "off",
  inputValue,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const isLabelSmall = focused || Boolean(ref.current?.value);
  const isBadInput = typeof errorMsg === "string";

  const [inputId, setInputId] = useState<string>("");
  const [labelId, setLabelId] = useState<string>("");

  useEffect(() => {
    setInputId(randomId());
    setLabelId(randomId());
  }, []);

  const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(() => {
    setFocused(false);
    setValidation?.(true);
    onBlur?.();
  }, [setValidation, onBlur]);

  const onInputChange: React.FocusEventHandler<HTMLInputElement> | string = useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.key === "Enter") {
        if (!errorMsg) {
          onEnter?.(e.currentTarget.value);
        }
        setValidation?.(true);
      }
    },
    [errorMsg, onEnter, setValidation],
  );

  const setInputOpacity = useCallback(() => {
    return isLabelSmall && placeholder
      ? "opacity-1"
      : !label || !placeholder
      ? "opacity-1"
      : "opacity-0";
  }, [isLabelSmall, label, placeholder]);

  const handleOnFocus = useCallback(() => setFocused(true), []);

  return (
    <div className="pos-r w-full">
      <div
        {...props}
        data-cy=""
        onClick={() => ref.current?.focus()}
        className={`${inputStyles.wrapper} ${borderStyles(focused, isBadInput)} ${
          className || ""
        } ${label ? "pt7" : ""} ${inputBgColor(focused, isBadInput)} cursor-text`}
      >
        {icon && <span className={`pr2 fs26 fs22-sm ${icon} ${iconColor(focused)}`} aria-hidden />}
        <Label label={label} id={labelId} htmlFor={inputId} isLabelSmall={isLabelSmall} />
        <input
          onChange={onInputChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          value={inputValue && inputValue}
          id={inputId}
          ref={ref}
          aria-label={ariaLabel}
          aria-labelledby={labelId}
          className={`${inputStyles.input} ${label && "top-1.5"} ${setInputOpacity()}`}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          defaultValue={defaultValue}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type '"data-cy"' can't be used to index type '{ defaultChecked?: boolean | undefined; suppressContentEditableWarning?: boolean | undefined; suppressHydrationWarning?: boolean | undefined; accessKey?: string | undefined; contentEditable?: Booleanish | ... 1 more ... | undefined; ... 245 more ...; onTransitionEndCapture?: TransitionEventHandler<...> | undefined; }'.
          data-cy={props["data-cy"]}
          autoComplete={autoComplete}
        />
      </div>
      <ErrorMessage msg={errorMsg} />
    </div>
  );
};

const borderStyles = (focused: boolean, error: boolean) =>
  error
    ? `border-2 border-solid border-error-700`
    : focused
    ? "border-blue-500 border-2 border-solid"
    : `border-2 border-solid border-gray-100`;

const iconColor = (focused: boolean): string => (focused ? "text-blue-500" : "text-gray400");

const inputBgColor = (focused: boolean, error: boolean) =>
  error ? "bg-white" : focused ? "bg-blue-100" : "bg-gray-100";

export default React.memo(TextInput);
